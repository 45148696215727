import { FC } from 'react';
import { Container, Title } from '../../styled/components';
import styled from '@emotion/styled';
import { $phoneWidth } from '../../styled/variables';
import { Button } from '../../components/form';
import { handleToggleAmoForm } from '../../components/fn';
import { HowImg, HowMobImg } from '../../assets/img';

// const config = [
//   {
//     date: 'до 4 ноября',
//     descr: 'Пройдите небольшой тест  и напишите мотивационное письмо с кратким рассказом о себе.',
//   },
//   {
//     date: 'до 18 ноября',
//     descr: 'Напишите эссе на одну из предложенных тем.',
//   },
//   {
//     date: 'до 16 декабря',
//     descr:
//       'Составьте план урока, подберите дополнительные материалы и представьте этот урок на видео.',
//   },
//   {
//     date: '',
//     descr:
//       'Подготовьтесь к собеседованию. На нем зададим вопросы о вашем уроке, педагогических методиках, воспитательных приёмах и роли учителя в современной системе образования.',
//   },
//   {
//     date: '22-23 февраля 2025',
//     descr:
//       'Примите участие в награждении в рамках образовательного форума — это будет отличная возможность пообщаться и обменяться опытом. Дорогу и проживание оплатим.',
//   },
// ];

export const HowWork: FC = () => {
  return (
    <Wrapper>
      <MyTitle>Как принять участие?</MyTitle>
      {/* <Content>
        <Row>
          <Item>
            <Label>
              <b>1</b>
              <span>{`{${config[0].date}}`}</span>
            </Label>
            <Descr>{config[0].descr}</Descr>
          </Item>
          <Item>
            <Label>
              <b>2</b>
              <span>{`{${config[1].date}}`}</span>
            </Label>
            <Descr>{config[1].descr}</Descr>
          </Item>
        </Row>
        <Row>
          <Item>
            <Label>
              <b>3</b>
              <span>{`{${config[2].date}}`}</span>
            </Label>
            <Descr>{config[2].descr}</Descr>
          </Item>
          <Item>
            <Label>
              <b>4</b>
            </Label>
            <Descr>{config[3].descr}</Descr>
          </Item>
          <Item>
            <Label>
              <b>5</b>
              <span>{`{${config[4].date}}`}</span>
            </Label>
            <Descr>{config[4].descr}</Descr>
          </Item>
        </Row>
      </Content> */}
      <Body>
        <img src={HowImg} />
        <img src={HowMobImg} />
      </Body>
      <MyButton onClick={handleToggleAmoForm} $fitContent>
        Принять участие
      </MyButton>
    </Wrapper>
  );
};

const Wrapper = styled(Container)`
  padding-top: 8rem;
  @media screen and (max-width: ${$phoneWidth}) {
    padding-top: 6rem;
  }
`;

const MyTitle = styled(Title)`
  margin-bottom: 3rem;
  @media screen and (max-width: ${$phoneWidth}) {
    margin-bottom: 2rem;
    text-align: center;
  }
`;

const Body = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  margin: 4rem 0;
  img {
    width: 100%;
    &:nth-of-type(2) {
      display: none;
    }
  }

  @media screen and (max-width: ${$phoneWidth}) {
    margin: 2rem 0;
    img {
      &:nth-of-type(1) {
        display: none;
      }
      &:nth-of-type(2) {
        display: block;
      }
    }
  }
`;

// const Row = styled.div`
//   display: flex;
//   justify-content: center;
//   height: 100%;
//   > *:not(:last-child) {
//     margin-right: 2rem;
//   }
//   @media screen and (max-width: ${$phoneWidth}) {
//     flex-direction: column;
//   }
// `;

// const Content = styled.div`
//   display: flex;
//   flex-direction: column;
//   > *:not(:last-child) {
//     margin-bottom: 2rem;
//   }
//   @media screen and (max-width: ${$phoneWidth}) {
//     > *:not(:last-child) {
//       margin-bottom: 0;
//     }
//   }
// `;

// const Item = styled.div`
//   display: flex;
//   flex-direction: column;
//   max-width: 30.06rem;
//   border: 0.25rem solid ${$primaryColor};
//   padding: 1rem 2rem 2rem;
//   border-radius: 2.31rem;
//   @media screen and (max-width: ${$phoneWidth}) {
//     width: 100%;
//     max-width: 100%;
//     margin-bottom: 1.5rem;
//   }
// `;

// const Label = styled.div`
//   display: flex;
//   align-items: flex-end;
//   margin-bottom: 2rem;
//   b {
//     font-style: italic;
//     font-size: 7.06rem;
//     font-weight: 400;
//     line-height: 6.06rem;
//     letter-spacing: 0%;
//     text-align: left;
//     text-transform: uppercase;
//     margin-right: 2rem;
//   }
//   span {
//     font-size: 1.5rem;
//     font-weight: 400;
//     line-height: 1.5rem;
//     letter-spacing: 0%;
//     text-align: left;
//   }
// `;

// const Descr = styled.p`
//   font-size: 1.5rem;
//   font-weight: 400;
//   line-height: 1.5rem;
// `;

const MyButton = styled(Button)`
  margin: 2rem auto 0;
  @media screen and (max-width: ${$phoneWidth}) {
    margin-top: -0.5rem;
    width: 100%;
  }
`;
