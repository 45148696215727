import { FC } from 'react';
import { Container, Title } from '../../styled/components';
import styled from '@emotion/styled';
import { Team1, Team2, Team3, TeamBorder } from '../../assets/img';
import { $phoneWidth, $primaryBg, $primaryColor } from '../../styled/variables';

const config = [
  {
    avatar: Team1,
    name: 'Пётр Тихонов',
    descr:
      'Научный сотрудник Государственной публичной исторической библиотеки России, в прошлом – учитель истории',
    text: 'Лидер проекта, ответственный за операционное управление',
  },
  {
    avatar: Team2,
    name: 'Борис Гаврилин',
    descr: 'Преподаватель Уральского федерального университета',
    text: 'Интеграция в сообщество и работа с учителями',
  },
  {
    avatar: Team3,
    name: 'Михаил Попов',
    descr: 'Преподаватель института Ispring',
    text: 'Контроль над методической и научной составляющей',
  },
];

export const Team: FC = () => {
  return (
    <Wrapper id="team">
      <MyTitle>Команда</MyTitle>
      <List>
        {config.map((item, index) => (
          <Item key={index}>
            <Avatar src={item.avatar} />
            <Name>{item.name}</Name>
            <Descr>{item.descr}</Descr>
            <Text>{item.text}</Text>
            <Border src={TeamBorder} />
          </Item>
        ))}
      </List>
    </Wrapper>
  );
};

const Wrapper = styled(Container)`
  padding-top: 8rem;
  @media screen and (max-width: ${$phoneWidth}) {
    padding-top: 6rem;
  }
`;

const MyTitle = styled(Title)`
  margin-bottom: 6rem;
  @media screen and (max-width: ${$phoneWidth}) {
    margin-bottom: 2rem;
  }
`;

const List = styled.ul`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  padding-right: 5rem;
  gap: 7rem;
  @media screen and (max-width: ${$phoneWidth}) {
    grid-template-columns: 1fr;
    gap: 3rem;
    padding-right: 0;
  }
`;

const Item = styled.li`
  position: relative;
  border: 0.25rem solid ${$primaryColor};
  border-radius: 2.31rem;
  background: ${$primaryBg};
  padding: 3.5rem 3rem;
  color: #fff;
  &::before {
    position: absolute;
    background-color: ${$primaryBg};
    top: 50%;
    right: 0;
    transform: translate(39%, -50%);
    content: '';
    width: 4.25rem;
    height: 4rem;
  }
  @media screen and (max-width: ${$phoneWidth}) {
    padding: 2rem 1.5rem;
    &::before {
      display: none;
    }
  }
`;

const Avatar = styled.img`
  width: 20.94rem;
  height: 20.94rem;
  border-radius: 2.31rem;
  border: 0.25rem solid ${$primaryColor};
  margin-bottom: 3.5rem;
  @media screen and (max-width: ${$phoneWidth}) {
    width: 100%;
    margin-bottom: 2rem;
  }
`;

const Name = styled.h3`
  font-size: 1.94rem;
  font-weight: 700;
  line-height: 2rem;
  margin-bottom: 2rem;
  @media screen and (max-width: ${$phoneWidth}) {
    margin-bottom: 1.5rem;
  }
`;

const Descr = styled.p`
  font-size: 1.5rem;
  font-weight: 400;
  line-height: 1.5rem;
  margin-bottom: 2rem;
`;

const Text = styled.p`
  font-size: 1.13rem;
  font-weight: 400;
  line-height: 1.13rem;
`;

const Border = styled.img`
  position: absolute;
  top: 50%;
  right: 0;
  transform: translate(39%, -50%);
  height: calc(100% + 6rem);
  @media screen and (max-width: ${$phoneWidth}) {
    display: none;
  }
`;
