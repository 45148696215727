import { SCREEN_LG, SCREEN_MD, SCREEN_SM } from '../utils/consts';

//variables
export const $desktopWidth = `${SCREEN_LG}px`;
export const $tableWidth = `${SCREEN_MD}px`;
export const $phoneWidth = `${SCREEN_SM}px`;

export const $mainFont = '"Meta Pro Cond", sans-serif';
export const $mainFontSize = '16px';
export const $mainBackgroundColor = '#ffffff';

export const $primaryColor = '#1A4919';
export const $primaryBg = '#266025';
export const $lightColor = '#ffffff';

export const $mainFontColor = $primaryColor;
