import styled from '@emotion/styled';
import { FC } from 'react';
import { Container, Title } from '../../styled/components';
import { IconTg, IconVk, IconYt } from '../../icons';
import { MAIL_LINK, PHONE_LINK, TELEGRAM_LINK, VK_LINK, YOUTUBE_LINK } from '../../utils/consts';
import { $phoneWidth, $primaryBg, $primaryColor } from '../../styled/variables';

export const Contacts: FC = () => {
  return (
    <Wrapper id="contacts">
      <Title>контакты</Title>
      <Content>
        <Item>
          <Icon href={VK_LINK}>
            <IconVk />
          </Icon>
          <Link href={`mailto:${MAIL_LINK}`} target="_blank">
            {MAIL_LINK}
          </Link>
        </Item>
        <Item>
          <Icon target="_blank" href={`https://t.me/${TELEGRAM_LINK}`}>
            <IconTg />
          </Icon>
          <Link href={`https://t.me/FondRumyantseva`} target="_blank">
            @FondRumyantseva
          </Link>
        </Item>
        <Item>
          <Icon href={YOUTUBE_LINK}>
            <IconYt />
          </Icon>
          <Link href={`tel:${PHONE_LINK}`}>{PHONE_LINK}</Link>
        </Item>
      </Content>
    </Wrapper>
  );
};

const Wrapper = styled(Container)`
  display: grid;
  grid-template-columns: auto 1fr;
  align-content: baseline;
  gap: 3rem;
  padding-top: 8rem;
  padding-bottom: 8rem;
  @media screen and (max-width: ${$phoneWidth}) {
    padding-top: 6rem;
    padding-bottom: 6rem;
    grid-template-columns: 1fr;
    gap: 2rem;
  }
`;

const Content = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 3rem;
  @media screen and (max-width: ${$phoneWidth}) {
    grid-template-columns: 1fr;
    gap: 2rem;
  }
`;

const Item = styled.div`
  display: flex;
  flex-direction: column;
`;

const Icon = styled.a`
  svg {
    width: 3rem;
    fill: ${$primaryColor};
    transition: fill 0.3s;
  }
  &:hover {
    svg {
      fill: ${$primaryBg};
    }
  }
`;

const Link = styled.a`
  font-size: 1.94rem;
  font-weight: 400;
  line-height: 2rem;
  transition: color 0.3s;
  &:hover {
    color: ${$primaryBg};
  }
`;
