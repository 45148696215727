import styled from '@emotion/styled';
import { FC } from 'react';
import { Container } from '../styled/components';
const NotFound: FC = () => {
  return <Wrapper>404 PAGE</Wrapper>;
};

const Wrapper = styled(Container)``;

export default NotFound;
