import { FC } from 'react';
import { Offer } from './Offer';
import { Values } from './Values';
import { HowWork } from './HowWork';
import { Rewards } from './Rewards';
import { HelmetHead } from '../../components/HelmetHead';

const Main: FC = () => {
  return (
    <>
      <HelmetHead title="Учитель истории 2024" descr="Учитель истории 2024" />
      <Offer />
      <Values />
      <HowWork />
      <Rewards />
    </>
  );
};

export default Main;
