import { FC } from 'react';
import { Container, Title } from '../../styled/components';
import styled from '@emotion/styled';
import { $phoneWidth, $primaryBg, $primaryColor } from '../../styled/variables';

const config = [
  {
    title: 'учителя формируют общество',
    descr:
      'Мы верим, что учитель определяет облик общества. Он формирует лицо нового поколения. Ценности настоящего учителя передаются его ученикам.',
  },
  {
    title: 'история воспитывает будущих граждан',
    descr:
      'Мы считаем, что история как школьный предмет должна воспитывать критически мыслящих, образованных, верящих в себя и Россию граждан, готовых брать ответственность за себя и за других, готовых менять страну к лучшему.',
  },
  {
    title: 'саморазвитие – важная часть преподавания',
    descr:
      'Мы убеждены, что хороший учитель постоянно развивается. Совершенствуя себя и свои уроки, он совершенствует своего ученика.',
  },
];

export const Values: FC<{ pd?: boolean }> = ({ pd }) => {
  return (
    <Wrapper $pd={pd} id="values">
      <MyTitle>Наши ценности</MyTitle>
      <List>
        {config.map(({ title, descr }, i) => (
          <Item key={i}>
            <Label>{title}</Label>
            <Descr>{descr}</Descr>
          </Item>
        ))}
      </List>
    </Wrapper>
  );
};

const Wrapper = styled(Container)<{ $pd?: boolean }>`
  padding-top: 8rem;
  @media screen and (max-width: ${$phoneWidth}) {
    padding-top: ${({ $pd }) => ($pd ? '2rem' : '6rem')};
  }
`;

const MyTitle = styled(Title)`
  font-size: 5rem;
  font-weight: 700;
  line-height: 4.38rem;
  letter-spacing: 0%;
  text-align: center;
  text-transform: uppercase;
  margin-bottom: 3rem;
  @media screen and (max-width: ${$phoneWidth}) {
    margin-bottom: 2rem;
  }
`;

const List = styled.ul`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  align-content: baseline;
  gap: 2rem;
  @media screen and (max-width: ${$phoneWidth}) {
    grid-template-columns: 1fr;
    gap: 1.5rem;
  }
`;

const Item = styled.li`
  background: ${$primaryBg};
  border: 0.25rem solid ${$primaryColor};
  border-radius: 2.31rem;
  color: #fff;
  padding: 3rem 2rem;
`;

const Label = styled.h3`
  font-family: 'Meta Pro';
  font-size: 3.13rem;
  font-weight: 700;
  line-height: 2.81rem;
  letter-spacing: 0%;
  text-align: left;
  text-transform: uppercase;
  margin-bottom: 4rem;
  @media screen and (max-width: ${$phoneWidth}) {
    font-size: 2rem;
    line-height: 100%;
    margin-bottom: 2rem;
  }
`;

const Descr = styled.p`
  font-size: 1.5rem;
  font-weight: 400;
  line-height: 1.5rem;
  @media screen and (max-width: ${$phoneWidth}) {
    font-size: 1.25rem;
    line-height: 100%;
  }
`;
