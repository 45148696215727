//global styled

import styled from '@emotion/styled';
import { $phoneWidth } from './variables';

export const Container = styled.div`
  width: 100%;
  max-width: 112.375rem;
  margin: 0 auto;
  padding: 0 1.5rem;
`;

export const Title = styled.h2`
  font-family: 'Meta Pro';
  font-size: 5rem;
  font-weight: 700;
  line-height: 4.38rem;
  letter-spacing: 0%;
  text-align: center;
  text-transform: uppercase;
  @media screen and (max-width: ${$phoneWidth}) {
    font-size: 3rem;
    line-height: 100%;
  }
`;
