import styled from '@emotion/styled';
import { FC } from 'react';
import { Container, Title } from '../../styled/components';
import { Support1, Support2, Support3, Support4, Support5 } from '../../assets/img';
import { $phoneWidth, $primaryBg } from '../../styled/variables';

const config = [
  {
    avatar: Support1,
    name: 'Сергей Волков',
    descr: 'Доктор исторических наук, профессор ПСТГУ',
  },
  {
    avatar: Support2,
    name: 'Алексей Савватеев',
    descr: 'Просветитель, доктор физико-математических наук, член-корреспондент РАН',
  },
  {
    avatar: Support3,
    name: 'Алексей Любжин',
    descr: 'Доктор филологических наук, специалист по истории российского образования',
  },
  {
    avatar: Support4,
    name: 'Василий Тополев',
    descr: 'Блогер',
  },
  {
    avatar: Support5,
    name: 'Василий Гурков',
    descr: 'Автор канала «Комнатный рыцарь»',
  },
];

export const Support: FC = () => {
  return (
    <Wrapper id="support">
      <Container>
        <MyTitle>нас поддерживают</MyTitle>
        <List $count={config.length}>
          {config.map(({ avatar, name, descr }) => (
            <Item key={name}>
              <Avatar src={avatar} />
              <Name>{name}</Name>
              <Descr>{descr}</Descr>
            </Item>
          ))}
        </List>
      </Container>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  padding: 3.5rem 0;
  color: #fff;
  background-color: ${$primaryBg};
  border-top: 0.25rem solid rgb(38, 96, 37);
  border-bottom: 0.25rem solid rgb(38, 96, 37);
`;

const MyTitle = styled(Title)`
  margin-bottom: 4.5rem;
  @media screen and (max-width: ${$phoneWidth}) {
    text-align: center;
  }
`;

const List = styled.ul<{ $count: number }>`
  display: grid;
  align-content: baseline;
  grid-template-columns: ${({ $count }) => `repeat(${$count}, 1fr)`};
  justify-content: space-between;
  @media screen and (max-width: ${$phoneWidth}) {
    grid-template-columns: 1fr;
    gap: 3rem;
  }
`;

const Item = styled.li`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  max-width: 20rem;
  margin: 0 auto;
  > *:not(:last-child) {
    margin-bottom: 1.5rem;
  }
`;

const Avatar = styled.img`
  object-fit: cover;
  width: 17.8125rem;
  height: 17.8125rem;
  border-radius: 2.31rem;
`;

const Name = styled.div`
  font-size: 1.88rem;
  font-weight: 700;
  line-height: 1.88rem;
`;

const Descr = styled.div`
  font-size: 1.5rem;
  font-weight: 400;
  line-height: 1.5rem;
`;
