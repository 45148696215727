import { FC } from 'react';
import { Container } from '../../styled/components';
import styled from '@emotion/styled';
import { Expert1, Expert2, Expert3, Expert4, Expert5, Expert6 } from '../../assets/img';
import { $phoneWidth } from '../../styled/variables';

const config = [
  {
    avatar:
      'https://cdn-s-static.arzamas.academy/storage/author/743/regular_photo-74f955d3-a837-4806-8b76-29fd954437bb.jpg',
    name: 'Олег Ауров',
    descr: 'Кандидат исторических наук, заведующий кафедрой всеобщей истории РАНХиГС',
  },
  {
    avatar: Expert5,
    name: 'Фёдор Гайда',
    descr: 'Доктор исторических наук, профессор исторического факультета МГУ имени М.В. Ломоносова',
  },
  {
    avatar: Expert1,
    name: 'Иван Грибков',
    descr:
      'Кандидат исторических наук, доцент РАНХиГС, финалист столичного конкурса «Учитель года-2019»',
  },
  {
    avatar: Expert6,
    name: 'Иван Косиченко',
    descr: 'Кандидат исторических наук, доцент РГГУ',
  },
  {
    avatar: Expert3,
    name: 'Александр Марей',
    descr: 'Кандидат юридических наук, доцент Факультета гуманитарных наук НИУ ВШЭ',
  },
  {
    avatar: Expert2,
    name: 'Кирилл Соловьев',
    descr: 'Доктор исторических наук, профессор РАН',
  },
  {
    avatar: Expert4,
    name: 'Андрей Тесля',
    descr:
      'Кандидат философских наук, доцент Высшей школы философии, истории и социальных наук БФУ им. И. Канта',
  },
  {
    avatar: 'https://media.pravoslavie.ru/314461.p.jpg?mtime=1553688097',
    name: 'Василий Цветков',
    descr: 'Доктор исторических наук, профессор МПГУ и РГГУ',
  },
];

export const Advice: FC = () => {
  return (
    <Wrapper>
      <Title>экспертный совет</Title>
      <List>
        {config.map(({ avatar, name, descr }) => (
          <Item key={name}>
            <Avatar src={avatar} />
            <Name>{name}</Name>
            <Descr>{descr}</Descr>
          </Item>
        ))}
      </List>
    </Wrapper>
  );
};

const Wrapper = styled(Container)`
  padding-top: 10rem;
  padding-bottom: 8rem;
  display: grid;
  align-content: baseline;
  grid-template-columns: 19.15rem 1fr;
  gap: 4rem;
  @media screen and (max-width: ${$phoneWidth}) {
    padding-top: 6rem;
    padding-bottom: 6rem;
    grid-template-columns: 1fr;
    gap: 3rem;
  }
`;

const Title = styled.div`
  font-size: 3.13rem;
  font-weight: 700;
  line-height: 2.81rem;
  text-transform: uppercase;
  text-align: left;
  @media screen and (max-width: ${$phoneWidth}) {
    text-align: center;
  }
`;

const List = styled.ul`
  display: grid;
  align-content: baseline;
  grid-template-columns: repeat(4, 1fr);
  gap: 4rem;
  @media screen and (max-width: ${$phoneWidth}) {
    gap: 3rem;
    grid-template-columns: 1fr;
  }
`;

const Item = styled.li`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  > *:not(:last-child) {
    margin-bottom: 1.5rem;
  }
`;

const Avatar = styled.img`
  width: 17.8125rem;
  height: 17.8125rem;
  border-radius: 2.31rem;
  object-fit: cover;
  object-position: center top;
`;

const Name = styled.div`
  font-size: 1.88rem;
  font-weight: 700;
  line-height: 1.88rem;
`;

const Descr = styled.div`
  font-size: 1.5rem;
  font-weight: 400;
  line-height: 1.5rem;
`;
