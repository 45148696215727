import { FC } from 'react';
import { Lvl1, Lvl2, Lvl3 } from '../../assets/img';
import { Container, Title } from '../../styled/components';
import styled from '@emotion/styled';
import { $phoneWidth } from '../../styled/variables';

export const Rewards: FC = () => {
  return (
    <Wrapper>
      <MyTitle>Призовые места</MyTitle>
      <Content>
        <Row>
          <Item>
            {/* <b>700 т.р.</b> */}
            <img src={Lvl2} />
            <span>приз жюри</span>
          </Item>
          <Item className="main-item">
            <b>1 млн. р.</b>
            <img src={Lvl3} />
            <span>лауреат премии</span>
          </Item>
          <Item>
            {/* <b>700 т.р.</b> */}
            <img src={Lvl2} />
            <span>лучший урок</span>
          </Item>
        </Row>
        <Row>
          <Item>
            {/* <b>500 т.р.</b> */}
            <img src={Lvl1} />
            <span>остальные призёры</span>
            <Count></Count>
          </Item>
          {/* <Item>
            <b>250 т.р.</b>
            <img src={Lvl1} />
            <span>призёры iI степени</span>
            <Count>{`{ 3 номинанта }`}</Count>
          </Item>
          <Item>
            <b>100 т.р.</b>
            <img src={Lvl1} />
            <span>призёры iII степени</span>
            <Count>{`{ 5 номинантов }`}</Count>
          </Item> */}
          <Item>
            {/* <b>100 т.р.</b> */}
            <img src={Lvl1} />
            <span>Народный выбор</span>
            <Count />
          </Item>
        </Row>
      </Content>
    </Wrapper>
  );
};

const Wrapper = styled(Container)`
  padding-top: 8rem;
  padding-bottom: 8rem;
  @media screen and (max-width: ${$phoneWidth}) {
    padding-top: 6rem;
    padding-bottom: 6rem;
  }
`;

const MyTitle = styled(Title)`
  margin-bottom: 3rem;
  @media screen and (max-width: ${$phoneWidth}) {
    margin-bottom: 2rem;
  }
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  > *:not(:last-child) {
    margin-bottom: 2rem;
  }
`;

const Row = styled.div`
  display: flex;
  align-items: flex-end;
  justify-content: center;
  > *:not(:last-child) {
    margin-right: 6rem;
  }
  @media screen and (max-width: ${$phoneWidth}) {
    flex-direction: column;
    align-items: center;
    > *:not(:last-child) {
      margin-right: 0;
      margin-bottom: 2rem;
    }
  }
`;

const Item = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  img {
    width: 20.22rem;
  }
  b {
    font-size: 3.75rem;
    font-weight: 700;
    line-height: 6.06rem;
  }
  span {
    font-size: 1.94rem;
    font-weight: 400;
    line-height: 2.88rem;
    letter-spacing: 0%;
    text-align: center;
    text-transform: uppercase;
  }
  &.main-item {
    b {
      font-size: 4.69rem;
      font-weight: 700;
      line-height: 6.06rem;
    }
    img {
      width: 24.875rem;
    }
    span {
      font-size: 3.13rem;
      line-height: 4.63rem;
    }
    @media screen and (max-width: ${$phoneWidth}) {
      order: -1;
      &.main-item {
        img {
          width: 100%;
        }
      }
    }
  }
`;

const Count = styled.div`
  font-size: 1.94rem;
  font-weight: 450;
  line-height: 2rem;
  letter-spacing: 0%;
  text-align: center;
  height: 2rem;
`;
