import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { $phoneWidth, $primaryBg, $primaryColor } from '../../styled/variables';

export const Button = styled.button<{ $theme?: 'primary' | 'secondary'; $fitContent?: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  font-family: 'Meta Pro Cond';
  font-size: 2.19rem;
  font-weight: 700;
  line-height: 3.25rem;
  text-align: center;
  text-transform: uppercase;
  height: 6.88rem;
  width: ${({ $fitContent }) => ($fitContent ? 'fit-content' : '100%')};
  border-radius: 4.06rem;
  border: 0.25rem solid ${$primaryColor};
  padding: 1rem 2rem;
  transition: color 0.3s, background-color 0.3s;
  @media screen and (max-width: ${$phoneWidth}) {
    height: 5rem;
    font-size: 1.5rem;
    line-height: 1.5rem;
    padding: 0.5rem 1rem;
  }

  ${({ $theme }) => {
    switch ($theme) {
      case 'secondary':
        return css`
          background-color: #fff;
          color: ${$primaryColor};
          &:hover {
            background-color: ${$primaryColor};
            color: #fff;
          }
        `;

      default:
        return css`
          background-color: ${$primaryBg};
          color: #fff;
          &:hover {
            background-color: ${$primaryColor};
          }
        `;
    }
  }}
`;
