import { FC } from 'react';
import { Container } from '../../styled/components';
import styled from '@emotion/styled';

const Agreement: FC = () => {
  return (
    <Wrapper>
      <Content>
        {`Согласие на обработку персональных данных
БЛАГОТВОРИТЕЛЬНЫЙ ФОНД ИМ. Н.П. РУМЯНЦЕВА

Пользователь, оставляя заявку, оформляя подписку, комментарий, запрос на
обратную связь, регистрируясь либо совершая иные действия, связанные с
внесением своих персональных данных на интернет-сайте учитель-истории.рф,
принимает настоящее Согласие на обработку персональных данных (далее –
Согласие)

Принятием Согласия является подтверждение факта согласия Пользователя со
всеми пунктами Согласия. Пользователь дает свое согласие организации
«БЛАГОТВОРИТЕЛЬНЫЙ ФОНД ИМ. Н.П. РУМЯНЦЕВА», которой принадлежит
сайт учитель-истории.рф на обработку своих персональных данных со
следующими условиями:
Пользователь дает согласие на обработку своих персональных данных, как без
использования средств автоматизации, так и с их использованием.
Согласие дается на обработку следующих персональных данных (не
являющихся специальными или биометрическими):
• фамилия, имя, отчество;
• адрес(а) электронной почты;
• иные данные, предоставляемые Пользователем.
Персональные данные пользователя не являются общедоступными.
1. Целью обработки персональных данных является предоставление полного
доступа к функционалу сайта учитель-истории.рф.
2. Основанием для сбора, обработки и хранения персональных данных
являются:
• Ст. 23, 24 Конституции Российской Федерации;
• Ст. 2, 5, 6, 7, 9, 18–22 Федерального закона от 27.07.06 года No152-ФЗ «О
персональных данных»;
• Ст. 18 Федерального закона от 13.03.06 года No 38-ФЗ «О рекламе»;
• Устав организации «БЛАГОТВОРИТЕЛЬНЫЙ ФОНД ИМ. Н.П. РУМЯНЦЕВА;
• Политика обработки персональных данных.

3. В ходе обработки с персональными данными будут совершены следующие
действия с персональными данными: сбор, запись, систематизация,
накопление, хранение, уточнение (обновление, изменение), извлечение,
использование, передача (распространение, предоставление, доступ),
обезличивание, блокирование, удаление, уничтожение.
4. Передача персональных данных, скрытых для общего просмотра, третьим
лицам не осуществляется, за исключением случаев, предусмотренных
законодательством Российской Федерации.

5. Пользователь подтверждает, что указанные им персональные данные
принадлежат лично ему.
6. Персональные данные хранятся и обрабатываются до момента ликвидации
организации «БЛАГОТВОРИТЕЛЬНЫЙ ФОНД ИМ. Н.П. РУМЯНЦЕВА».
Хранение персональных данных осуществляется согласно Федеральному
закону No125-ФЗ «Об архивном деле в Российской Федерации» и иным
нормативно правовым актам в области архивного дела и архивного хранения.
7. Пользователь согласен на получение информационных сообщений с сайта
учитель-истории.рф. Персональные данные обрабатываются до отписки
Пользователя от получения информационных сообщений.
8. Согласие может быть отозвано Пользователем либо его законным
представителем, путем направления Отзыва согласия на электронную почту –
manager@uchitelistorii.ru с пометкой «Отзыв согласия на обработку
персональных данных». В случае отзыва Пользователем согласия на обработку
персональных данных организация «БЛАГОТВОРИТЕЛЬНЫЙ ФОНД ИМ. Н.П.
РУМЯНЦЕВА» вправе продолжить обработку персональных данных без
согласия Пользователя при наличии оснований, указанных в пунктах 2 - 11
части 1 статьи 6, части 2 статьи 10 и части 2 статьи 11 Федерального закона
No152-ФЗ «О персональных данных» от 27.07.2006 г. Удаление персональных
данных влечет невозможность доступа к полной версии функционала сайта
учитель-истории.рф

9. Настоящее Согласие является бессрочным, и действует все время до
момента прекращения обработки персональных данных, указанных в п.7 и п.8
данного Согласия.
10. Место нахождения организации «БЛАГОТВОРИТЕЛЬНЫЙ ФОНД ИМ. Н.П.
РУМЯНЦЕВА» в соответствии с учредительными документами: 119017, город
Москва, Пятницкая ул, д. 37, помещ. 1/1
        `}
      </Content>
    </Wrapper>
  );
};

const Wrapper = styled(Container)`
  padding: 20px;
`;

const Content = styled.div`
    width: fit-content;
    margin: 0 auto;
  white-space: pre-wrap;
  line-height: 1.5;
  font-family: Arial, sans-serif;
  font-size: 1rem;
  color: #000;
`;

export default Agreement;
