import { FC } from 'react';
import { IconVk, IconTg, IconYt } from '../../icons';
import { VK_LINK, TELEGRAM_LINK, YOUTUBE_LINK } from '../../utils/consts';
import styled from '@emotion/styled';
import { $phoneWidth, $primaryBg, $primaryColor } from '../../styled/variables';

export const Text: FC = () => {
  return (
    <Wrapper>
      <Title>поддержите русских историков</Title>
      <Descr>
        Развитие образования в России — наше общее дело. Изменить школу к лучшему способно только
        деятельное сообщество российских учителей. Ради его поддержки мы создали премию «Учитель
        истории».
      </Descr>
      <Socials>
        <a href={VK_LINK} target="_blank">
          <IconVk />
        </a>
        <a href={`https://t.me/${TELEGRAM_LINK}`} target="_blank">
          <IconTg />
        </a>
        <a href={YOUTUBE_LINK} target="_blank">
          <IconYt />
        </a>
      </Socials>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const Title = styled.h1`
  color: ${$primaryColor};
  font-size: 7rem;
  font-weight: 900;
  line-height: 7.06rem;
  text-align: left;
  text-transform: uppercase;
  margin-bottom: 2rem;
  max-width: 48rem;
  @media screen and (max-width: ${$phoneWidth}) {
    font-size: 3.63rem;
    line-height: 3.38rem;
    margin-bottom: 1.25rem;
  }
`;

const Descr = styled.p`
  font-size: 1.5rem;
  font-weight: 400;
  line-height: 1.5rem;
  letter-spacing: 0%;
  text-align: left;
  max-width: 33rem;
  margin-bottom: 1.5rem;
  @media screen and (max-width: ${$phoneWidth}) {
    font-size: 1rem;
    line-height: 1.13rem;
    margin-bottom: 1.25rem;
  }
`;

const Socials = styled.div`
  display: flex;
  margin-top: auto;
  > *:not(:last-child) {
    margin-right: 1.5rem;
  }

  a {
    &:hover {
      svg {
        fill: ${$primaryBg};
      }
    }
  }

  svg {
    width: 5rem;
    height: 5rem;
    fill: ${$primaryColor};
    transition: fill 0.3s;
    @media screen and (max-width: ${$phoneWidth}) {
      width: 4rem;
      height: 4rem;
    }
  }
`;
